$red: #e50305;
$orange: #ff8b00;
$yellow: #ffee02;
$green: #01812a;
$blue: #014cff;
$purple: #77088a;
$black: #010101;
$brown: #623a16;
$aqua: #74d7ee;
$pink: #ffafc8;
$white: #fff;
$creme: #f4ebda;

$body: "PT Serif", "Times New Roman", Times, serif;
$headings: "Ultra", "Times New Roman", Times, serif;
* {
  box-sizing: border-box;
  font-family: $body !important;
}
h1,
h2,
h3,
h4,
h5,
a,
button {
  font-family: $headings !important;
}
body {
  background: $creme;
  height: 100vh;
}
.loading-container {
  background-color: $creme;
  height: 100vh;
  z-index: 20;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 20;
  p {
    font-family: $headings;
  }
}
.go-to-link {
  &:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }
}
button {
  text-align: center;
  color: rgb(29, 29, 29);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 13px;
  padding-bottom: 5px;
  border-radius: 40px;
  width: 100%;
  border: 0px;
  font-size: 15px;
  line-height: 1;
  padding: 15px 20px;
  width: 100%;
  white-space: nowrap;
  max-width: none;
  display: flex;
  width: auto;
}
h1 {
  padding-top: 60px;
  font-size: 40px !important;
  margin-bottom: 20px !important;
}
.search-input {
  color: $creme;
  border: 0;
  background-color: #1d1d1d;
  border-radius: 20px !important;
  width: 100%;
  max-width: 100%;
  padding: 5px 45px 5px 20px;
  font-size: 15px;
  line-height: 15px;
  background-image: url("./icons/magnifying-glass-solid.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center right 15px;
}
.search-input::placeholder {
  color: $creme;
  font-weight: 500;
  opacity: 1;
  transform: translateY(3px);
}
.MuiPaper-rounded {
  border-radius: 20px !important;
}
.cluster {
  * {
    color: black !important;
  }
  div {
    line-height: 1;
    z-index: 5;
    position: relative;
    &:before {
      position: absolute;
      width: 20px;
      height: 20px;
      content: "";
      display: flex;
      background-color: $white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      border-radius: 50%;
    }
  }
}
.single-chip {
  background: #1d1d1d !important;
  color: white !important;
  padding-top: 7px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
#locationInfoContainer {
  height: auto;
  background: #f4ebda;
  > * {
    color: #1d1d1d;
  }
  .location-info-button {
    color: #f4ebda;
    cursor: pointer;
    background-color: #1d1d1d !important;
    padding: 5px 15px 5px 15px;
    border: 0;
    border-radius: 20px;
    & > * {
      font-weight: 700;
    }
  }
}
header {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  z-index: 500;
  width: 100%;
  .header-inner {
    padding: 10px 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #f4ebda;
    height: 70px;
    .logo {
      height: 50px;
    }
    h1 {
      padding: 0;
      margin: 0;
      margin-bottom: 0 !important;
      font-size: 35px !important;
      letter-spacing: 1.2px;
      span {
        font-family: "PT Serif", "Times New Roman", Times, serif !important;
      }
    }
    .header-item {
      text-align: center;
      display: flex;
      height: 100%;
      &.left {
        max-width: 75px;
        width: 75px;
        align-items: center;
        justify-content: flex-start;
      }
      &.center {
        text-align: center;
        display: flex;
        height: 70px;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      &.right {
        max-width: 75px;
        width: 75px;
        text-align: right;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
#listContainer {
  height: calc(100vh - 70px);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: $creme;
  margin-top: 70px;
}

.info-container-outer {
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
  &.open {
    background-color: rgba(0, 0, 0, 0.8);
    pointer-events: auto;
    transition: 150ms linear all;
  }
}
.info-container,
.menu-container {
  position: fixed;
  overflow: scroll;
  z-index: 600;
  transform: translateX(-105%);
  transition: 350ms ease-in-out all;
  background: $creme;
  color: #fff;
  padding: 70px 55px 55px 55px;
  border-radius: 22px;
  font-weight: 300;
  max-width: 600px;
  transition: linear 150ms all;
  left: 50%;
  top: 50%;
  max-height: 90vh;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  max-width: 800px;
  opacity: 0;
  height: auto;
  .bottom {
    h1 {
      font-size: 30px !important;
      letter-spacing: 1.3px;
      margin: 0 0 30px 0 !important;
      padding: 0 !important;
      color: $black;
    }
  }
  .info-close {
    background: white;
    color: $black;
    cursor: pointer;
    margin: 30px auto 0 auto;
    transition: 150ms linear all;
    &:hover {
      background: $black;
      transition: 150ms linear all;
      color: white;
    }
  }
  &.open {
    opacity: 1;
    pointer-events: auto;
    transition: linear 150ms opacity;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}
.header-button {
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  .info-icon {
    width: 100%;
    height: 100%;
  }
}

.info-container,
.menu-container {
  .top {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    button {
      align-items: center;
      justify-content: center;
      display: flex;
      padding: 15px;
      border: 0;
      background: transparent;
      cursor: pointer;
    }
  }
  p {
    margin-bottom: 15px;
    text-align: left;
    color: $black;
    font-family: $body;
    font-size: 18px;
    line-height: 1.3;
    a {
      font-family: $body !important;
      color: $black;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.menu-container {
  top: 10px;
  right: 10px;
  left: initial;
  height: calc(100vh - 20px);
  transform: translateX(110%);
  transition: linear 350ms 150ms transform;
  &.open {
    transform: TranslateX(0);
    transition: linear 350ms 150ms transform;
  }
}
#listContainer {
  .jss6,
  .makeStyles-container-4 {
    width: 100%;
  }
}
#listContainer .MuiGrid-container {
  padding-bottom: 50px;
  overflow: scroll;
  width: 100%;
}
ul.menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  li:not(:last-child) {
    margin-bottom: 30px;
  }
  .dot {
    width: 100%;
    height: 100%;
    position: relative;
    &.active {
      a {
        text-decoration: underline;
      }
    }
  }
  a {
    text-decoration: none;
    color: #000;
    font-size: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
}
.drawer-toggle {
  height: 100vh;
  position: sticky;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  padding: 0;
  transition: 150ms linear all;
  display: none;
  &:hover {
    background: #fff;
    transition: 150ms linear all;
  }
}
.MuiGrid-item {
  transition: 350ms 150ms linear all;
  .MuiGrid-container {
    transition: 150ms linear all;
    overflow: hidden;
  }
}
$colors: $red, $orange, $yellow, $green, $blue, $purple, $black, $brown, $aqua,
  $pink, $white, $red, $orange, $yellow, $green, $blue, $purple, $black, $brown,
  $aqua, $pink, $white, $red, $orange, $yellow, $green, $blue, $purple, $black,
  $brown, $aqua, $pink, $white, $red, $orange, $yellow, $green, $blue, $purple,
  $black, $brown, $aqua, $pink, $white;

.MuiCardActions-root {
  a {
    background-color: transparent;
  }
}
.MuiGrid-root.MuiGrid-container {
  height: calc(100vh - 70px) !important;
}

button[color="primary"] {
  background-color: $white;
  cursor: pointer;
  color: $black;
  transition: 150ms linear all;
  padding: 15px 20px !important;
  white-space: nowrap !important;
  max-width: none !important;
  display: flex !important;
  width: auto !important;
  &:hover {
    background-color: $black;
    color: $white !important;
    transition: 150ms linear all;
  }
}

.image {
  aspect-ratio: 3 / 2;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
  margin-bottom: 20px;
}

@for $i from 1 through length($colors) {
  #listContainer .MuiGrid-item:nth-child(#{$i}) {
    .MuiPaper-root {
      background-color: rgba(nth($colors, $i), 0.3);
      color: #000;
    }
  }
}

@media only screen and (max-width: 959px) {
  .drawer-toggle {
    display: flex;
  }
}

@media only screen and (max-width: 805px) {
  header .header-inner h1 {
    font-size: 25px !important;
  }
}
@media only screen and (max-width: 650px) {
  header .header-inner h1 {
    padding: 0 15px !important;
    font-size: 20px !important;
  }
  .info-container,
  .menu-container {
    padding: 55px 25px 25px 25px;
  }
}
@media only screen and (max-width: 500px) {
  .MuiTypography-h5 {
    font-size: 20px;
  }
}
